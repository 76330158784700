var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "event-board", staticStyle: { "text-align": "center" } },
        [
          _c(
            "div",
            { staticClass: "medical-banner-202408" },
            [
              _vm._l(_vm.sectionCount, function (sIndex) {
                return _c(
                  "div",
                  {
                    key: sIndex,
                    class: "dv-div" + sIndex.toString() + " dv-div-wrap",
                  },
                  _vm._l(
                    _vm.stepStates.slice(
                      (sIndex - 1) * _vm.sectionStepCount,
                      sIndex * _vm.sectionStepCount
                    ),
                    function (state, index) {
                      return _c(
                        "div",
                        {
                          key: sIndex * _vm.sectionStepCount + index + 1,
                          class:
                            "medical" + (index + 1).toString() + " open-event",
                        },
                        [
                          _c("a", [
                            _c("img", {
                              class: _vm.getImageClass(state),
                              style: _vm.getImageStyle(state),
                              attrs: { src: _vm.getImagePath(state) },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.getImageClick(state, sIndex)
                                },
                              },
                            }),
                          ]),
                        ]
                      )
                    }
                  ),
                  0
                )
              }),
              _c("div", { staticClass: "btn-open-detail" }, [
                _c(
                  "a",
                  {
                    attrs: { title: "이벤트 자세히 보러가기" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.$router
                          .push({
                            name: "app-event-main",
                            query: { id: _vm.noticeId, entry: 191 },
                          })
                          .catch(function () {})
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/events/event240710/event-detail.png"),
                        alt: "이벤트 자세히 보러가기",
                      },
                    }),
                  ]
                ),
              ]),
              _c("img", {
                attrs: {
                  src: require("@/assets/images/events/event240710/banner-bg-2408.png"),
                  alt: "메디컬 트렌드",
                },
              }),
            ],
            2
          ),
        ]
      ),
      _c(_vm.popupComponent, {
        ref: "preProcessPopup",
        tag: "component",
        attrs: { "event-id": _vm.eventId },
        on: { close: _vm.closePreProcessPopup },
      }),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
      _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }